<template>
</template>
<script>
export default {
    beforeCreate(){
        console.log(this.$route.query);
        parent?.window?.smartroComplete?.(this.$route.query);
        opener?.window?.smartroComplete?.(this.$route.query);
        if(parent || opener) window.close();
    },
}
</script>